<template>
  <div class="box">
    <div class="title">
      <div class="topBox">
        <div class="pillar"></div>
        <span>教育培训</span>
      </div>
      <div class="pointer more" @click="handleMore">
        <span class="moreFont">更多</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div class="list">
      <div class="item" v-for="(item, index) in dataList" :key="index">
        <div class="imgBox pointer img-wrap">
          <el-image :src="item.cover_url" fit="cover" class="img"></el-image>
          <div class="mark">
            <i
                class="el-icon-video-play cursor"
                @click="handleContent(item)"
            ></i>
          </div>
        </div>
        <div class="contentBox">
          <div class="contentTitle">{{ item.name | textFormat }}</div>
          <!-- <div class="content"><span class="pointer" @click="handleContent">{{ item.content | fullTextFormat(35) }}</span></div> -->
        </div>
      </div>
    </div>
    <videoPlay ref="vidoePlay"></videoPlay>
  </div>
</template>

<script>
import videoPlay from '@/baseComponents/videoPlay'

export default {
  name: "education",
  components: {
    videoPlay
  },
  data() {
    return {
      dataList: [],
      loading: false
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.loading = true
      let params = {
        source: this.PJSource,
        start: 0,
        limit: 3,
        home_flag:1
      }
      let res = await this.$store.dispatch('API_company/association_video_List', params)
      this.loading = false
      if (res.success) {
        console.log(res);
        this.dataList = res.data
      }
    },
    handleMore() {
      this.$router.push('/college')
    },
    handleContent(item) {
      if (this.IS_LOGIN) {
        this.$router.push({
            path:"/college_detail",
            query:{
                parameter:this._encode({
                    video_id:item.id,
                }),
            },
        })
      } else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.$router.push({
                path:"/college_detail",
                query:{
                    parameter:this._encode({
                        video_id:item.id,
                    }),
                },
            })

          },
        });
      }


    }
  }
}
</script>

<style scoped lang="less">
.box {
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    align-items: flex-end;
    font-weight: 800;
    .topBox {
      display: flex;
      align-items: flex-end;
      color: #FFFFFF;
      .pillar {
        width: 10px;
        height: 63px;
        background: #409eff;
        margin-right: 20px;
      }
    }

  }

  .more {
    width: 80px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 15px;
    border: 1px solid #C0C4CC;
    text-align: center;
    line-height: 30px;
    color: #909399;
    font-size: 14px;
  }

  .list {
    margin-top: 50px;
    display: flex;
    // justify-content: space-between;

    .item {
      width: 386px;
      margin-right:21px;
      .imgBox {
        width: 386px;
        height: 240px;
        overflow: hidden;
        border-radius: 10px 10px 0px 0px;
      }
      .img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 10px 10px 0px 0px;
      }
      .contentBox {
        .contentTitle {
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          width: 386px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }

        .content {
          margin-top: 6px;
          color: #1F292E;
          width: 386px;
          word-wrap: break-word;
        }
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.img-wrap {
  position: relative;

  .mark {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 240px;
    line-height: 240px;
    text-align: center;

    .el-icon-video-play {
      font-size: 30px;
      color: #aaa;
    }
  }

  .mark:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  .mark:hover .el-icon-video-play {
    color: #fff;
  }
}
</style>