<template>
  <div class="box">
    <div class="boxLeft">
      <div class="boxLeftTitle">新闻资讯</div>
      <div class="top">
        <el-tabs v-model="activeName" @tab-click="handleClickCenterTab">
          <el-tab-pane
            v-for="(item, index) in tags"
            :name="item.name"
            :key="index"
          >
            <span slot="label" class="titleBox">
              <span class="title">{{ item.label }}</span>
            </span>
          </el-tab-pane>
        </el-tabs>
        <div class="moreBox pointer" @click="handleMore">
          <span class="more">更多</span>
          <i class="el-icon-arrow-right colore3954f"></i>
        </div>
      </div>
      <div v-loading="leftLoading" class="bottonContainer">
        <noDataImg v-if="!leftLoading && leftList.length === 0"></noDataImg>
        <div class="bottom" v-if="leftList.length > 0">
          <div class="listLeft">
            <div class="imgBox" @click="goDetail(leftList[0])">
              <al-image
                :src="leftList[0].img_url ? leftList[0].img_url : defaultImg"
                fit="cover"
                class="img pointer"
              ></al-image>
            </div>
            <div class="itemTitle">
              <span
                class="pointer"
                @click="goDetail(leftList[0])"
                :title="leftList[0].title | textFormat"
              >
                {{ leftList[0].title | textFormat }}
              </span>
            </div>
            <div class="itemContent">
              <span class="pointer" @click="goDetail(leftList[0])">{{
                leftList[0].content | fullTextFormat(50)
              }}</span>
            </div>
            <div class="date">
              {{ leftList[0].created_time | secondFormat("LL") }}
            </div>
            <div class="moreBox pointer" @click="goDetail(leftList[0])">
              <div class="moreText">查看更多</div>
              <div class="moreIconBox">
                <i class="el-icon-arrow-right moreIcon"></i>
              </div>
            </div>
          </div>
          <div class="listRight">
            <div
              v-for="(item, index) in leftList"
              :key="index"
              class="rightItem"
            >
              <div v-if="index > 0" class="itemContent">
                <div class="pointer itemTitle" @click="goDetail(item)">
                  {{ item.title | textFormat }}
                </div>
                <div class="date">
                  {{ item.created_time | secondFormat("LL") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="boxRight">
      <advertising :advertising="advertising"></advertising>
    </div>
  </div>
</template>

<script>
import advertising from "~dls/components/index/home/advertising";
export default {
  name: "activity",
  components: { advertising },
  data() {
    return {
      activeName: "3",
      tags: [
        {
          label: "行业动态",
          name: "3",
        },
        {
          label: "协会活动",
          name: "1",
        },
        {
          label: "政策法规",
          name: "4",
        },
      ],
      leftList: [],
      leftLoading: false,
      rightList: [],
      rightLoading: false,
      advertising: {},
    };
  },
  computed: {
    defaultImg() {
      let img = "";
      switch (this.activeName) {
        case "3":
          img =
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/active2.jpg";
          break;
        case "4":
          img =
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/active.jpg";
          break;
        case "1":
          img =
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/active1.jpg";
          break;
        case "7":
          img =
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/active3.jpg";
          break;
        case "1":
          img =
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/active1.jpg";
          break;
      }
      return img;
    },
  },
  created() {
    this.getLeftList();
    this.getAdvertisingList();
  },
  methods: {
    async getLeftList() {
      let params = {
        content_type: this.activeName,
        source: this.PJSource,
        nopage: 0,
        limit: 4,
        start: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      this.leftLoading = true;
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      this.leftLoading = false;
      if (res.success) {
        this.leftList = res.data;
      }
    },
    async getActivityList() {
      let params = {
        source: this.PJSource,
        nopage: 0,
        limit: 4,
        start: 0,
        is_show: 1,
        is_top:1,
        language: this.LOCALE === "en" ? 1 : 2,
        user_id:this.USER_INFO.id,
        company_id:this.USER_INFO.company_id
      };
      this.leftLoading = true;
      let res = await this.$store.dispatch(
        "API_index/association_event",
        params
      );
      this.leftLoading = false;
      if (res.success) {
        res.data.forEach(val=>{
          val.img_url = val.poster;
          val.title = val.name_zh || val.name_en;
          val.content = val.desc_zh;
        });
        this.leftList = res.data;
      }
    },
    goDetail(item) {
      // if (this.activeName === '1') {
      //   let routeUrl = this.$router.resolve({
      //       path: "/activityDetail",
      //       query:{ id: item.id }
      //   });
      //   window.open(routeUrl.href, "_blank");
      // } else {
      //   this.$router.push({
      //     path: "/detail",
      //     query: { id: item.id },
      //   });
      // }
      this.$router.push({
        path: "/detail",
        query: { id: item.id },
      });
    },
    handleClickCenterTab() {
      // if (this.activeName !== '1') {
      //   this.getLeftList();
      // } else {
      //   this.getActivityList();
      // }
      this.getLeftList();
    },
    handleMore() {
      // if (this.activeName === '1') {
      //   this.$router.push({
      //     path: "/activity",
      //   })
      // } else {
      //   this.$router.push({
      //     path: "/activitys",
      //     query: {
      //       content_type: this.activeName * 1,
      //     },
      //   });
      // }
      this.$router.push({
        path: "/activitys",
        query: {
          content_type: this.activeName * 1,
        },
      });
    },
    async getAdvertisingList() {
      let params = {
        language: this.LOCALE === "en" ? 1 : 2,
        source: this.PJSource,
        serial_no: "1",
      };
      let res = await this.$store.dispatch(
        "API_index/getAdvertisingList",
        params
      );
      if (res.success) {
        if (res.data.length > 0) {
          res.data[0].extend = JSON.parse(res.data[0].extend);
        }
        this.advertising = res.data[0];
      }
    },
  },
};
</script>

<style scoped lang="less">
.box {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  .boxLeft {
    width: 800px;
    .boxLeftTitle {
      font-weight: 900;
      color: #192f59;
      font-size: 24px;
      padding-bottom: 15px;
    }
    .top {
      padding-top: 4px;
      width: 100%;
      height: 46px;
      overflow: hidden;
      position: relative;
      .titleBox {
        padding-left: 36px;
        padding-right: 36px;
      }
      /deep/ .el-tabs__nav-wrap::after {
        background-color: #dcdfe6;
        border-bottom: 2px;
      }
      /deep/ .el-tabs__item {
        font-size: 18px;
        font-weight: 800;
        color: #7d8695;
      }
      /deep/ .el-tabs__item {
        font-size: 18px;
        font-weight: 800;
        color: #909399;
      }
      /deep/ .el-tabs__item.is-active {
        color: #1f292e;
      }
      /deep/ .el-tabs__active-bar {
        background-color: #409eff;
      }
      .moreBox {
        position: absolute;
        right: 0;
        top: 10px;
        padding: 5px 19px;
         color: #e3954f;

        .more {
          font-size: 14px;
          color: #e3954f;
        }
      }
    }
    .bottonContainer {
      min-height: 333px;
      position: relative;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
      .listLeft {
        width: 364px;
        .imgBox {
          width: 352px;
          height: 175px;
          //background: #cccccc;
          .img {
            width: 352px;
            height: 175px;
          }
        }
        .itemTitle {
          padding: 8px 0 8px;
          font-size: 16px;
          color: #1f292e;
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          max-width: 364px;
        }
        .date {
          margin-top: 6px;
          color: #7d8695;
          font-size: 14px;
        }
        .itemContent {
          color: #7d8695;
          font-size: 14px;
        }
        .moreBox {
          margin-top: 14px;
          display: flex;
          align-items: center;
          width: 80px;
          .moreText {
            color: #909399;
            margin-right: 6px;
          }
          .moreIconBox {
            width: 16px;
            height: 16px;
            background: #192f59;
            border-radius: 8px;
            text-align: center;
            line-height: 16px;
            .moreIcon {
              font-size: 10px;
              color: #ffffff;
            }
          }
        }
      }
      .listRight {
        width: 400px;
        position: relative;
        min-height: 303px;
        .rightItem {
          .itemContent {
            color: #7d8695;
            font-size: 14px;
            line-height: 26px;
            border-bottom: 1px solid #dcdfe6;
            padding-bottom: 13px;
            padding-top: 14px;
            .itemTitle {
              width: 400px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
          .date {
            font-size: 14px;
            color: #7d8695;
          }
        }
        .rightItem:nth-child(2) .itemContent {
          padding-top: 0;
        }
      }
    }
  }
  .boxRight {
    width: 364px;
    height: 426px;
  }
  .pointer {
    cursor: pointer;
  }
}
.color909399 {
  color: #909399;
}
</style>