<template>
  <div class="container">
    <div class="left">
      <div class="title">
        <span>风险公示</span>
        <div class="moreBox pointer" @click="handleLeftMore" v-if="leftList.length > 0">
          <span>更多</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="content" v-loading="leftLoading">
        <div v-for="(item, index) in leftList" :key="index" class="leftItme">
          <div class="company textOverflow"><span class="pointer" :title="item.company_name | textFormat" @click="handleLeftItem(item)">{{ item.company_name | textFormat }}</span></div>
          <div class="cause textOverflow" :title="item.cases | textFormat">{{ item.cases | textFormat }}</div>
          <div class="money textOverflow" :title="item.delinquent | textFormat">{{ item.delinquent | textFormat }}</div>
        </div>
        <noDataImg v-if="!leftLoading && leftList.length === 0"></noDataImg>
      </div>
    </div>
    <div class="right">
      <div class="title">
        <span>下载专区</span>
        <div class="moreBox pointer" @click="handleRightMore" v-if="rightCount > 4">
          <span>更多</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="content" v-loading="rightLoading">
        <div v-for="(item, index) in rightList" :key="index" class="rightItme">
          <div class="sanjiao"></div>
          <div class="name textOverflow"><span class="pointer" @click="handleRightItem(item)" :title="item.name">{{ item.name }}</span></div>
        </div>
        <noDataImg v-if="!rightLoading && rightList.length === 0"></noDataImg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "risks",
  data() {
    return {
      leftList: [],
      leftLoading: false,
      rightList: [],
      rightLoading: false,
      rightCount: 0
    }
  },
  mounted() {
    this.getLeftList()
    this.getRightList()
  },
  methods: {
    async getLeftList() {
      this.leftLoading = true
      let parmas = {
        start: 0,
        limit: 4,
        source: this.PJSource
      }
      let res = await this.$store.dispatch("API_index/getHomeWarning", parmas);
      this.leftLoading = false
      if (res.success) {
        this.leftList = res.data
      }
    },
    async getRightList() {
      this.rightLoading = true
      let parmas = {
        source: this.PJSource,
        limit: 4,
        is_show: 1,
        start: 0,
        language: this.LOCALE === "en" ? 1 : 2,
      }
      let res = await this.$store.dispatch("API_index/downloadList", parmas);
      this.rightLoading = false
      if (res.success) {
        this.rightList = res.data
        this.rightCount = res.total
      }
    },
    handleLeftItem(item) {
      this.$router.push({
        path: "/warningList",
        query: {
          company_name: item.company_name
        },
      });
    },
    handleRightItem(item) {
      window.open(item.url)
    },
    handleLeftMore() {
      this.$router.push('/warningList')
    },
    handleRightMore() {
      this.$router.push('/downloadSection')
    },
  }
}
</script>

<style scoped lang="less">
.container {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;

  .left {
    width: 756px;
    .leftItme {
      padding: 20px 0;
      border-bottom: 1px solid #DCDFE6;
      display: flex;
      justify-content: space-between;
      .company {
        width: 260px;
        font-size: 14px;
        color: #1F292E;
      }
      .cause {
        width: 220px;
      }
      .money {
        width: 150px;
      }
    }
  }

  .right {
    width: 356px;
    .rightItme {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px dashed #C0C4CC;
      .sanjiao {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 10px solid red;
        border-bottom: 5px solid transparent;
      }
      .name {
        width: 336px;

      }
    }
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 800;
    color: #192F59;
    font-size: 24px;

    .moreBox {
      padding: 5px 19px;
      background: #FFFFFF;
      border-radius: 15px;
      border: 1px solid #C0C4CC;
      color: #909399;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .content {
    position: relative;
    margin-top: 6px;
    min-height: 240px;
  }

  .pointer {
    cursor: pointer;
  }
}
</style>