<template>
  <div class="box">
    <div class="right">
      <div class="title">指导单位</div>
      <!-- <div class="list" v-loading="loading">
        <div v-for="(item, index) in imgList" :key="index" class="item" @click="gotoWebsite(item)" :title="item.title">
          <al-image class="item-wrap" style="background: #fff;" :src="item.src"
                    fit="scale-down" />
        </div>
      </div> -->
      <el-carousel indicator-position="outside" trigger="click" height="290px" :arrow="dataList.length>1?'always':'never'">
        <el-carousel-item v-for="(item,index) in dataList" :key="index">
          <div class="itemBox" v-for="(ee,vv) in item" :key="vv" :class="ee.company_id?'cursor':''">
            <el-image
                style="width: 100%; height: 100%"
                :src="ee.logo"
                fit="scale-down" 
                @click="jumpPage(ee)"/>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import {splitArr} from "~/baseUtils";

export default {
  name: "cooperation",
  data() {
    return {
      dataList: [],
      dataListWrap: [],
      imgList: [
        {
          src:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/directedby1.png',
          url:'http://ningbo.chinatax.gov.cn/yinzhou/',
          title:'国家税务总局宁波市税务局'
        },
        {
          src:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/directedby2.png',
          url:'http://kab.ningbo.gov.cn/',
          title:'宁波市人民政府口岸办公室'
        },
        {
          src:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/directedby3.jpg',
          url:'http://www.nbyz.gov.cn/col/col1229108413/index.html',
          title:'鄞州区民政局'
        },
        {
          src:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/directedby4.png',
          url:'http://www.nbyz.gov.cn',
          title:'鄞州区人民政府'
        },
        {
          src:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/directedby5.jpg',
          url:'http://www.nbyz.gov.cn/col/col1229108747/index.html',
          title:'鄞州区商务局'
        },
      ],
      showDialog: false,
      previewList: [],
      value1: 0,
      loading: false,
      
    };
  },
  created() {
  },
  mounted() {
    // this.vicePresidentList()
    this.getList()
  },
  methods: {
    gotoWebsite(item) {
      window.open(item.url, "_blank")
    },
    async getList(){
      this.loading = true
      let params={}
      params.nopage=1
      params.source=this.PJSource
      let res=await this.$store.dispatch("API_index/getList",params)
      if(res.success){
        let data=res.data
        // console.log(res)
        this.dataList=splitArr(res.data,6)
        this.loading = false
        // console.log(this.dataList)
      }
    },
    jumpPage(ee){
      if(ee.company_id){
        let res=this.$router.resolve({
          path:"/companyDetail",
          query:{
            parameter:this._encode({
              company_id:ee.company_id
            })
          }
        })
        window.open(res.href,"_blank")
      }
    }
    // async vicePresidentList() {
    //   try {
    //     this.loading = true
    //     let params = {}
    //     params.source = 7
    //     params.genre = 6
    //     params.nopage=1
    //     let res = await this.$store.dispatch("API_index/getunitTypeList", params)
    //     this.loading = false
    //     if (res.success) {
    //       this.dataListWrap = res.data
    //       this.imgList = splitArr(this.dataListWrap, 5)
    //     }
    //   } catch (e) {
    //     this.loading = false
    //     console.log(e)
    //   }
    // },
  }
};
</script>

<style scoped lang="less">
.box {
  padding-top: 30px;

  .right {
    width: 100%;
  }

  .title {
    font-weight: 800;
    color: #192F59;
    font-size: 24px;
    margin-bottom: 30px;
  }
  .list {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .item {
      width: 220px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      font-weight: 800;
      color: #FFFFFF;
      font-size: 24px;
      background-color: #fff;
      cursor: pointer;
      /deep/ .el-image{
        background: #fff!important;
      }
    }
    .bg409eff {
      background: #409eff;
    }
    .bg192F59 {
      background: #192F59;
    }
  }
}

.imgSilder {
  width: 100%;

  /deep/ .el-carousel {
    width: 100%;
  }

  /deep/ .el-carousel__arrow {
    display: none;
  }

  /deep/ .el-carousel__container {
    height: 60px;
    width: 100%;
  }

  .img-wrap {
    width: 220px;
    height: 60px;
    background: #fff;
    margin-right: 25px;

    .item-img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .img-wrap:nth-child(5) {
    margin-right: 0;
  }
}
/deep/ .el-image__inner{
  width: 386px;
  height: 100px;
  background: white;
}
/deep/ .el-carousel__item{
  display: flex;
  flex-wrap: wrap;
  
}
.itemBox{
  margin-right: 21px;
}
.itemBox:nth-child(3n){
  margin-right: 0px;
}
</style>
