<template>
  <div class="box">
    <div class="boxLeft">
      <div>
        <span class="title">人才专区</span>
        <span class="message">您有一条新的招聘信息请查收！</span>
      </div>
      <div class="list" v-loading="leftLoading">
        <div class="item" v-for="(item, index) in leftList" :key="index">
          <div class="item_left">
            <img :src="icons[index]" alt="" class="img" />
            <div class="adress pointer" @click="handleLeft(item.id)">
              {{ item.name }}
            </div>
          </div>
          <span class="item_right date"
            >{{ item.city_name_en | priorFormat(item.city_name_zh, LOCALE) }} |
            {{ item.experience | experienceFormat(LOCALE) }} |
            {{ item.level_require | levelTypeFormat(LOCALE) }}</span
          >
        </div>
        <noDataImg v-if="!leftLoading && leftList.length === 0"></noDataImg>
      </div>
    </div>
    <div class="boxCenter clearfix">
      <div class="company fl">
        <div class="title">会员动态</div>
        <div class="list" v-loading="centerLoading">
          <div class="item" v-for="(item, index) in centerList" :key="index">
            <div
              class="city"
              :title="
                item.city_name_en | priorFormat(item.city_name_zh, LOCALE)
              "
            >
              {{ item.city_name_en | priorFormat(item.city_name_zh, LOCALE) }}
            </div>
            <div class="name">
              <span
                class="pointer"
                @click="goCompanyDetail(item)"
                :title="
                  item.company_name_en
                    | priorFormat(item.company_name_zh, LOCALE)
                "
              >
                {{
                  item.company_name_en
                    | priorFormat(item.company_name_zh, LOCALE)
                }}
              </span>
            </div>
          </div>
          <noDataImg
            v-if="!centerLoading && centerList.length === 0"
          ></noDataImg>
        </div>
      </div>
    </div>
    <div class="boxRight">
      <div class="title">
        <span>会员走访</span>
        <div class="moreBox pointer" @click="handleMore">
          <span class="more">更多</span>
          <i class="el-icon-arrow-right color909399"></i>
        </div>
      </div>
      <div class="boxRightContent" v-loading="rightLoading">
        <div v-if="rightList.length > 0">
          <div class="imageBox">
            <al-image
              :src="rightList[0].img_url ? rightList[0].img_url : banner"
              class="img"
              fit="cover"
            ></al-image>
          </div>
          <div class="imageContent">
            <span class="pointer" @click="handleRightItem(rightList[0])">{{
              rightList[0].title | fullTextFormat(50)
            }}</span>
          </div>
          <div class="date">
            {{ rightList[0].created_time | secondFormat("LL") }}
          </div>
        </div>
        <noDataImg v-if="!rightLoading && rightList.length === 0"></noDataImg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "schoolCooperation",
  props: {
    advertising3: {
      type: Object,
      default: null,
    },
  },
  filters: {
    strings() {
      return "11111";
    },
  },
  data() {
    return {
      icons: [
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon1.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon2.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon3.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon4.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon5.png",
      ],
      banner:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/hyzf.jpg",
      leftList: [],
      centerList: [],
      rightList: [],
      leftLoading: false,
      centerLoading: false,
      rightLoading: false,
    };
  },
  mounted() {
    this.getLeftList();
    this.getCenterList();
    this.getRightList();
  },
  methods: {
    async getLeftList() {
      // 获取职位推荐
      this.leftLoading = true;
      let parmas = {
        start: 0,
        limit: 5,
        is_hot: 1,
        source: this.PJSource,
      };
      let data = await this.$store.dispatch(
        "API_position/getJobPositionList",
        parmas
      );
      this.leftLoading = false;
      if (data.success) {
        console.log("data.data", data.data);
        this.leftList = data.data;
      }
    },
    async getCenterList() {
      this.centerLoading = true;
      let params = {
        source: this.PJSource,
        nopage: 0,
        start: 0,
        limit: 5,
      };
      let res = await this.$store.dispatch(
        "API_index/memberCompanysearch",
        params
      );
      this.centerLoading = false;
      if (res.success) {
        this.centerList = res.data;
      }
    },
    async getRightList() {
      this.rightLoading = true;
      let params = {
        content_type: 8,
        source: this.PJSource,
        nopage: 0,
        limit: 1,
        start: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      this.rightLoading = false;
      if (res.success) {
        this.rightList = res.data;
      }
    },
    goCompanyDetail(item) {
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: item.company_id,
          }),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    handleLeft(id) {
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id,
          }),
        },
      });
    },
    handleMore() {
      this.$router.push({
        path: "/activitys",
        query: {
          content_type: 8,
        },
      });
    },
    handleRightItem(item) {
      this.$router.push({
        path: "/detail",
        query: { id: item.id },
      });
    },
  },
};
</script>

<style scoped lang="less">
.box {
  margin-top: 36px;
  display: flex;
  width: 100%;
  min-height: 200px;
  padding-bottom: 38px;
  .boxLeft {
    width: 410px;
    margin-right: 84px;

    .title {
      color: #192f59;
      font-size: 24px;
      margin-right: 16px;
      font-weight: 800;
    }

    .message {
      font-size: 14px;
      color: #909399;
    }

    .list {
      position: relative;
      padding-top: 4px;
      min-height: 220px;

      .item {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        .item_left {
          display: flex;
        }

        .img {
          margin-right: 10px;
        }

        .adress {
          color: #1f292e;
          margin-right: 10px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .item_right {
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .date {
          color: #999;
        }
      }
    }
  }

  .boxCenter {
    width: 315px;

    .company {
      width: 310px;
      margin-right: 15px;
    }

    .title {
      color: #192f59;
      font-size: 24px;
      font-weight: 800;
    }

    .tableHead {
      margin-top: 13px;
      height: 35px;
      background: #fff6f6;
      font-weight: 500;
      display: flex;
      align-items: center;

      .table1 {
        margin-left: 25px;
        width: 210px;
        color: #909399;
      }

      .table2 {
        width: 407px;
        color: #909399;
      }

      .table3 {
        color: #909399;
      }
    }

    .list {
      height: 180px;
      position: relative;
      padding-top: 4px;

      .item {
        display: flex;
        margin-top: 25px;

        .city {
          width: 75px;
          background: #409eff;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          border-radius: 4px 4px 4px 0px;
          margin-right: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 19px;
        }

        .name {
          width: 235px;
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .date {
          color: #ff5155;
        }
      }
    }

    .img-wrap {
      width: 498px;
      height: 225px;
      position: relative;

      .icon {
        position: absolute;
        top: 5px;
        left: -3px;
      }

      .bannerInner {
        height: 100%;
        background-size: 100% 100%;
        position: relative;

        .bannerBtn {
          position: absolute;
          padding: 0px 20px;
          border: 1px solid;
          border-radius: 4px;
          transform: translate(50%, 50%);

          &.btnStyle1 {
            border-radius: 0px;
          }

          &.btnStyle2 {
            border-radius: 4px;
          }

          &.btnStyle3 {
            border-radius: 999px;
          }
        }
      }
    }
  }

  .boxRight {
    width: 364px;

    .title {
      color: #192f59;
      font-size: 24px;
      font-weight: 800;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .moreBox {
        padding: 5px 19px;
        font-size: 14px;
        color: #909399;
        background: #ffffff;
        border-radius: 15px;
        border: 1px solid #c0c4cc;
        font-weight: 400;
      }
    }
    .boxRightContent {
      height: 200px;
      position: relative;
      .imageBox {
        margin-top: 20px;
        width: 364px;
        height: 142px;
        .img {
          width: 100%;
          height: 100%;
        }
      }
      .imageContent {
        margin-top: 6px;
        color: #7d8695;
        word-break: normal;
        height: 36px;
      }
      .date {
        margin-top: 4px;
        font-size: 14px;
        color: #7d8695;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.color909399 {
  color: #909399;
}
</style>
