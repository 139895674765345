<template>
  <div class="container">
    <carouselBanner paddingTop="24%" :bannerType="1"></carouselBanner>
	  <baseShippingDate style="background: #F5F5F5" :useType="2" @handleSearch="handleShipSearch" :searchTopBg="'#f5f5f5'"></baseShippingDate>
    <div class="content_area">
      <activity />
      <div class="apply" @click="goApply">
        <el-image
            style="width: 1200px; height: 120px"
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/my_banner.jpg"
            fit="scale-down"></el-image>
      </div>
      <schoolCooperation/>
    </div>
    <div class="education">
      <div class="content_area">
        <education />
      </div>
    </div>
    <div class="content_area">
      <risks></risks>
    </div>
    <div class="cooperation">
      <div class="content_area">
        <cooperation />
      </div>
    </div>
  </div>
</template>

<script>
import carouselBanner from '@/baseComponents/carouselBanner'
import activity from '~dls/components/index/home/activity'
import schoolCooperation from '~dls/components/index/home/schoolCooperation'
import education from '~dls/components/index/home/education'
import risks from '~dls/components/index/home/risks'
import cooperation from '~dls/components/index/home/cooperation'
import baseShippingDate from "~/baseComponents/baseShippingDate/components/search"
export default {
  metaInfo:{
    title:'东北物流'
  },
  components: {
    carouselBanner,
    activity,
    schoolCooperation,
    education,
    risks,
    cooperation,
	baseShippingDate
  },
  data() {
    return {

    };
  },
  computed: {},
  created() {
  },
  methods: {
    goApply() {
      this.$router.push('/memberShip')
    },
	handleShipSearch(val,val2) {
	  if (val.date) val.date = this.$moment(val.date).unix()
	  this.$router.push({
		path: '/baseShippingDate',
		query: {
		  polCode: val.polCode,
		  podCode: val.podCode,
		  date: val.date,
		  polName: val2.polName,
		  podName: val2.podName
		}
	  })
	}
  },
};
</script>
<style scoped lang="less">
  .container {
    background: #FFFFFF;
    .apply {
      margin-top: 30px;
      width: 100%;
      height: 120px;
      background: #D8D8D8;
      line-height: 120px;
      color: #909399;
      font-size: 40px;
      text-align: center;
      cursor: pointer;
    }
    .education {
      width: 100%;
      background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/homeBg1.png");
      height: 480px;
      background-size: 100% 100%;
    }
    .cooperation {
      margin-top: 38px;
      width: 100%;
      background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/homeBg2.png");
      height: 400px;
      background-size: 100% 100%;
    }
    .pointer {
      cursor: pointer;
    }
  }
</style>
